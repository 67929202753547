import axios from 'axios'
import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'

const state = {
  authData: sessionStorage.getItem('authData'),
  capability: sessionStorage.getItem('capability'),
  token: sessionStorage.getItem('token'),
  domain: sessionStorage.getItem('domain'),
  userName: '',
  companyName: '',
  isAuthenticated: false
}

const getters = {
  authData: (state) => state.authData,
  capability: (state) => state.capability,
  token: (state) => state.token,
  domain: (state) => state.domain,
  userName: (state) => state.userName,
  companyName: (state) => state.companyName,
  isAuthenticated: (state) => state.isAuthenticated
}

const mutations = {
  authData (state, authData) {
    state.authData = authData
    localStorage.setItem('authData', JSON.stringify(authData))
  },
  capability (state, capability) {
    state.capability = capability
    localStorage.setItem('capability', capability)
  },
  token (state, token) {
    state.token = token
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    sessionStorage.setItem('token', token)
  },
  domain (state, domain) {
    state.domain = domain
    if (domain) {
      axios.defaults.baseURL = `https://xs041845.xsrv.jp/ogrewatch/${domain}`
      localStorage.setItem('domain', domain)
    } else {
      axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'
      localStorage.removeItem('domain')
    }
  },
  userName (state, userName) {
    state.userName = userName
  },
  companyName (state, companyName) {
    state.companyName = companyName
  },
  isAuthenticated (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
    sessionStorage.setItem('isAuthenticated', isAuthenticated)
  }
}

const actions = {
  //
  // マスターログイン
  login ({ getters, commit, dispatch }) {
    console.log('▶️TRY MASTER LOGIN')
    if (!getters.authData) {
      return
    }
    axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'
    axios.defaults.headers.common.Authorization = ''
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jwt-auth/v1/token/', getters.authData)
        .then((response) => {
          console.log(response.data)
          commit('capability', response.data.capability)
          commit('domain', response.data.branch_slug)
          commit('userName', response.data.user_display_name)
          commit('companyName', response.data.company_name)
          commit('token', response.data.token)
          if (response.data.capability === 'superadmin') {
            commit('isAuthenticated', true)
            dispatch('Client/getList', null, { root: true })
            // get client list
            if (router.currentRoute._rawValue.name === 'Login') {
              router.push({ name: 'Admin' })
            }
          } else {
            dispatch('branchLogin')
          }
        })
        .catch((error) => {
          console.log(error)
          commit('isAuthenticated', false)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ブランチログイン
  branchLogin ({ getters, commit, dispatch }) {
    console.log('▶️TRY BRANCH LOGIN')
    if (!getters.authData) {
      return
    }
    commit('processing', true, { root: true })
    axios.defaults.headers.common.Authorization = ''
    axios
      .post('/wp-json/jwt-auth/v1/token/', getters.authData)
      .then((response) => {
        console.log(response.data)
        commit('token', response.data.token)
        commit('capability', response.data.capability)
        commit('isAuthenticated', true)
        // get daily attendance log
        router.push({ name: 'Attendance', params: { dom: getters.domain } })
      })
      .catch((error) => {
        console.log(error)
        commit('isAuthenticated', false)
      })
      .finally(() => {
        commit('processing', false, { root: true })
      })
  },
  //
  // keepAlive
  keepAlive ({ getters, commit }) {
    if (sessionStorage.getItem('token')) {
      commit('token', sessionStorage.getItem('token'))
      commit('isAuthenticated', Boolean(sessionStorage.getItem('isAuthenticated')))
      commit('authData', JSON.parse(localStorage.getItem('authData')))
      commit('domain', localStorage.getItem('domain'))
      commit('capability', localStorage.getItem('capability'))
    } else {
      router.push({ name: 'Login' })
    }
  },
  //
  // ログアウト
  logout ({ commit }) {
    commit('token', '')
    commit('domain', '')
    commit('capability', '')
    commit('userName', '')
    commit('companyName', '')
    commit('isAuthenticated', 0)
    router.push({ name: 'Login' })
  }
}

const Auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Auth
