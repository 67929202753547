import axios from 'axios'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'

const state = {
  list: [],
  name: '',
  uuid: '',
  row: {},
  staffData: {}
}

const getters = {
  list: (state) => state.list,
  name: (state) => state.name,
  uuid: (state) => state.uuid,
  row: (state) => state.row,
  staffData: (state) => state.staffData
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  name (state, name) {
    state.name = name
  },
  uuid (state, uuid) {
    state.uuid = uuid
  },
  row (state, row) {
    state.row = row
  },
  staffData (state, staffData) {
    state.staffData = staffData
  }
}

const actions = {
  //
  // スタッフリスト取得
  async loadList ({ commit }) {
    console.log('▶️try load staff list')
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/ktkr/v1/staff/list')
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ詳細取得
  async loadDetail ({ commit }, uuid) {
    return new Promise((resolve) => {
      axios
        .get('/wp-json/ktkr/v1/staff/' + uuid)
        .then((response) => {
          // console.log(response.data)
          commit('name', response.data.employeeName)
          commit('uuid', response.data.employee_id)
          commit('staffData', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  //
  // 更新
  async update ({ getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/ktkr/v1/staff/update', getters.row)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ削除
  async delete ({ commit }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .delete('/wp-json/ktkr/v1/staff/delete/' + id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Staff = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Staff
