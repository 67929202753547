<template>
  <div>
    <header :class="{ admin: !$route.params.dom && isAuthenticated }">
      <div>
        <h1><router-link :to="{ name: 'Root' }">Kintai Watch</router-link></h1>
        <span
            v-if="!$route.params.dom && isAuthenticated"
            @click="logout">ログアウト</span>
        <nav v-if="$route.params.dom">
          <ul>
            <li class="root"><router-link :to="{ name: 'Attendance' }"><i class="fa-regular fa-id-card"></i>出勤状況</router-link></li>
            <li><router-link :to="{ name: 'Log' }"><i class="fa-solid fa-book"></i>月次記録</router-link></li>
            <li><router-link :to="{ name: 'StaffList' }"><i class="fa-solid fa-users"></i>スタッフ</router-link></li>
            <li><router-link :to="{ name: 'Setting' }"><i class="fa-solid fa-screwdriver-wrench"></i>設定</router-link></li>
          </ul>
          <span
            v-if="isAuthenticated"
            @click="logout"><i class="fa-solid fa-right-from-bracket"></i>ログアウト</span>
        </nav>
      </div>
    </header>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: this.$store.getters['Auth/domain'],
      companyName: '',
      isAuthenticated: this.$store.getters['Auth/isAuthenticated'],
      cap: this.$store.getters['Auth/capability']
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/companyName'],
      (newValue) => {
        this.companyName = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/isAuthenticated'],
      (newValue) => {
        this.isAuthenticated = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
    console.log(`
isAuth: ${this.isAuthenticated}
capability: ${this.cap}
`)
  },
  methods: {
    setMeta (route) {
      if (route.meta.title) {
        const setTitle = route.meta.title + ' | Kintai Watch 勤怠管理'
        document.title = setTitle
      }
      if (this.$store.getters.companyName) {
        const setTitle = route.meta.title + ` | ${this.$store.getters.companyName}`
        document.title = setTitle
      }
    },
    logout () {
      this.$store.dispatch('Auth/logout')
    }
  },
  computed: {
  },
  watch: {
    '$route' (route, from) {
      this.setMeta(route)
    }
  }
}
</script>
