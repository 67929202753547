<template>
  <div id="shade">
    <div class="modal">
      <h1 id="logo">Kintai Watch</h1>
      <div>
        <dl>
          <dt>メールアドレス</dt>
          <dd>
            <input
              type="email"
              v-model="authData.username"
            >
          </dd>
          <dt>パスワード</dt>
          <dd>
            <input
              type="password"
              v-model="authData.password"
              @keydown.enter="login"
            >
          </dd>
        </dl>
        <button @click="login">ログイン</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      authData: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    if (localStorage.getItem('authData')) {
      this.authData = JSON.parse(localStorage.getItem('authData'))
    }
  },
  methods: {
    login () {
      this.$store.commit('Auth/authData', this.authData)
      this.$store.dispatch('Auth/login')
    }
  }
}
</script>
