import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import RootView from '../views/RootView.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'Root',
    meta: { title: 'ダッシュボード' },
    component: RootView,
    beforeEnter: (to, from, next) => {
      if (store.getters['Auth/capability'] === 'superadmin') {
        next({ name: 'Admin' })
      } else {
        next({ name: 'Attendance', params: { dom: store.getters['Auth/domain'] } })
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'ログイン' },
    component: LoginView,
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { title: '管理者ダッシュボード' },
    component: () => import(/* webpackChunkName: "Admin" */ '../views/AdminView.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('Auth/domain', false)
      store.dispatch('Auth/login')
      store.commit('Log/list', [])
      store.commit('Log/stats', [])
      store.commit('Staff/list', [])
      next()
    }
  },
  {
    path: '/:dom',
    beforeEnter: (to, from, next) => {
      store.commit('Log/list', [])
      store.commit('Log/stats', [])
      store.commit('Log/ready', false)
      to.params.dom = store.getters['Auth/domain']
      store.dispatch('Client/loadData', to.params.dom)
      store.dispatch('Staff/loadList')
      const today = new Date()
      const ym = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2)
      store.dispatch('Log/chkIsFixed', ym)
      next()
    },
    children: [
      {
        path: '',
        name: 'Attendance',
        meta: { title: '日次出勤状況' },
        component: () => import(/* webpackChunkName: "Attendance" */ '../views/client/AttendanceView.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Log/list', [])
          next()
        }
      },
      {
        path: 'staff',
        name: 'StaffList',
        meta: { title: 'スタッフリスト' },
        component: () => import(/* webpackChunkName: "StaffList" */ '../views/client/StaffList.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Staff/loadList')
          next()
        }
      },
      {
        path: 'setting',
        name: 'Setting',
        meta: { title: '基本勤務時間設定' },
        component: () => import(/* webpackChunkName: "Setting" */ '../views/client/SettingView.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Client/loadData', to.params.dom)
          next()
        }
      },
      {
        path: 'log',
        children: [
          {
            path: '',
            name: 'Log',
            meta: { title: '月次勤務記録' },
            component: () => import(/* webpackChunkName: "Log" */ '../views/client/LogView.vue'),
            beforeEnter: (to, from, next) => {
              store.commit('Log/list', [])
              // store.commit('Log/stats', [])
              // const today = new Date()
              // const ym = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2)
              // store.dispatch('Log/chkIsFixed', ym)
              next()
            }
          },
          {
            path: ':uuid',
            name: 'StaffDetail',
            meta: { title: '勤務記録詳細' },
            component: () => import(/* webpackChunkName: "StaffDetail" */ '../views/client/StaffDetail.vue'),
            beforeEnter: (to, from, next) => {
              store.commit('Log/list', [])
              // store.commit('Log/stats', [])
              store.dispatch('Staff/loadDetail', to.params.uuid)
              const today = new Date()
              const ym = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2)
              store.dispatch('Log/getStaffLog', {
                uuid: to.params.uuid,
                ym: ym
              })
              next()
            }
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (!store.getters['Auth/isAuthenticated']) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
