import { createStore } from 'vuex'
import Auth from './Auth.js'
import Client from './Client.js'
import Staff from './Staff.js'
import Log from './Log.js'

export default createStore({
  state: {
    processing: false,
    companyName: '',
    ymd: new Date()
  },
  getters: {
    processing: (state) => state.processing,
    companyName: (state) => state.companyName,
    ymd: (state) => state.ymd
  },
  mutations: {
    processing (state, processing) {
      state.processing = processing
      const body = document.querySelector('body')
      if (processing) {
        body.classList.add('processing')
      } else {
        body.classList.remove('processing')
      }
    },
    companyName (state, companyName) {
      state.companyName = companyName
    },
    ymd (state, ymd) {
      state.ymd = ymd
    }
  },
  actions: {
  },
  modules: {
    Auth,
    Client,
    Staff,
    Log
  }
})
