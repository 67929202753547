import axios from 'axios'
import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'

const state = {
  list: '',
  stats: false,
  row: {},
  isFixed: false,
  ready: false
}

const getters = {
  list: (state) => state.list,
  stats: (state) => state.stats,
  row: (state) => state.row,
  isFixed: (state) => state.isFixed,
  ready: (state) => state.ready
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  stats (state, stats) {
    state.stats = stats
  },
  row (state, row) {
    state.row = row
  },
  isFixed (state, isFixed) {
    state.isFixed = isFixed
  },
  ready (state, ready) {
    state.ready = ready
  }
}

const actions = {
  //
  // 日別出退勤ログ取得
  async getDaily ({ commit, dispatch }, ymd) {
    const thedate = ymd.split('-')
    dispatch('chkIsFixed', `${thedate[0]}-${thedate[1]}`)
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/ktkr/v1/log/daily/' + ymd)
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 月別出退勤ログ取得
  async getMonthly ({ getters, commit }, ym) {
    console.log('get monthly log')
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/ktkr/v1/log/monthly/' + ym)
        .then((response) => {
          console.log(response.data)
          commit('ready', true)
          commit('stats', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ別月別出退勤ログ取得
  async getStaffLog ({ commit, dispatch }, data) {
    // dispatch('chkIsFixed', data.ym)
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v1/log/staff/', data)
        .then((response) => {
          if (response.data) {
            console.log(response.data)
            commit('list', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ更新
  update ({ getters, commit, dispatch }, ymd) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/ktkr/v1/log/update', getters.row)
        .then((response) => {
          if (router.currentRoute._rawValue.name === 'Attendance') {
            dispatch('getDaily', ymd)
          }
          if (router.currentRoute._rawValue.name === 'StaffDetail') {
            const date = new Date(ymd)
            const ym = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
            dispatch('getStaffLog', {
              uuid: router.currentRoute._rawValue.params.uuid,
              ym: ym
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ追加
  async add ({ getters, commit, dispatch }, ymd) {
    console.log(ymd)
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v1/log/add/', getters.row)
        .then((response) => {
          console.log(response.data)
          if (router.currentRoute._rawValue.name === 'Attendance') {
            dispatch('getDaily', ymd)
          }
          if (router.currentRoute._rawValue.name === 'StaffDetail') {
            const date = new Date(ymd)
            const ym = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
            dispatch('getStaffLog', {
              uuid: router.currentRoute._rawValue.params.uuid,
              ym: ym
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // ログ削除
  async delete ({ getters, commit, dispatch }, ymd) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v1/log/delete/', getters.row)
        .then((response) => {
          console.log(response.data)
          if (router.currentRoute._rawValue.name === 'Attendance') {
            dispatch('getDaily', ymd)
          }
          if (router.currentRoute._rawValue.name === 'StaffDetail') {
            const date = new Date(ymd)
            const ym = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
            dispatch('getStaffLog', {
              uuid: router.currentRoute._rawValue.params.uuid,
              ym: ym
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 締めデータ生成
  async fix ({ commit }, yymm) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v1/log/fix/', yymm)
        .then((response) => {
          console.log(response.data)
          commit('isFixed', true)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 締め状態解除
  async unfix ({ commit }, yymm) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v1/log/unfix/', yymm)
        .then((response) => {
          console.log(response.data)
          commit('isFixed', false)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 月別締めデータ取得
  async getFixedLog ({ getters, commit, dispatch }, yymm) {
    console.log('get fixed log')
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      commit('stats', [])
      axios
        .get('/wp-json/ktkr/v1/log/fixed/' + yymm)
        .then((response) => {
          console.log(response.data)
          if (response.data.closing_flg === '1') {
            commit('isFixed', true)
            commit('ready', true)
            commit('stats', response.data.closing_list)
          } else {
            commit('isFixed', false)
            dispatch('getMonthly', yymm)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 対象月が締め済みか確認
  chkIsFixed ({ commit, dispatch }, yymm) {
    console.log('check is fixed')
    axios
      .get('/wp-json/ktkr/v1/log/chk-is-fixed/' + yymm)
      .then((response) => {
        console.log(response.data.closing_flg)
        if (response.data.closing_flg === '1') {
          commit('isFixed', true)
          if (router.currentRoute._rawValue.name !== 'Attendance') {
            dispatch('getFixedLog', yymm)
          }
        } else {
          commit('isFixed', false)
          if (router.currentRoute._rawValue.name !== 'Attendance') {
            dispatch('getMonthly', yymm)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const Log = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Log
