import axios from 'axios'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/ogrewatch'

const state = {
  list: '',
  clientData: {}
}

const getters = {
  list: (state) => state.list,
  clientData: (state) => state.clientData
}

const mutations = {
  list (state, list) {
    state.list = list
  },
  clientData (state, clientData) {
    state.clientData = clientData
  }
}

const actions = {
  //
  // Get client list
  async getList ({ getters, commit }) {
    console.log('try get client list')
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/ktkr/v1/client/list')
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント詳細取得
  async loadData ({ getters, commit }, domain) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/ktkr/v1/client/' + domain)
        .then((response) => {
          console.log(response.data)
          commit('clientData', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント情報更新
  async update ({ getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/ktkr/v2/client/update', getters.clientData)
        .then((response) => {
          // console.log(response.data)
          alert('企業情報を更新しました')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアントステータス有効無効
  async toggleStatus ({ commit, dispatch }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/ktkr/v2/client/togglestatus/' + id)
        .then((response) => {
          console.log(response.data)
          dispatch('getList')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント追加
  async addClient ({ getters, commit, dispatch }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/ktkr/v2/client/add', getters.clientData)
        .then((response) => {
          console.log(response.data)
          dispatch('getList')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert('既に存在する企業名(半角英数)です。')
          }
          if (error.response.status === 406) {
            alert('企業名(半角英数)に半角英数以外が含まれています。')
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント削除
  async deleteClient ({ getters, commit, dispatch }, id) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .delete('/wp-json/ktkr/v2/client/delete/' + id)
        .then((response) => {
          console.log(response.data)
          dispatch('getList')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Client = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default Client
